<template>
    <header class="md:bg-center bg-cover pt-20 min-h-screen">
      <div v-for="i in [currentIndex]" :key="i" class="h-full">
        <div class="relative text-center">
          <!-- Wrapper para contenido central -->
          <div class="md:flex bg-center">
            {{ i.currentImg }}
            <!-- Aplicamos clases de Tailwind CSS para tamaño y posición -->
            <div
              class="text-6xl text-white font-serif absolute mt-32 h-32"
              v-for="i in [currentTexto, currentText]"
              :key="i"
            >
              <div class="ml-20">
                <h1 class="hidden md:mt-96 uppercase rounded-xl">
                  {{ currentTxt }}
                </h1>
              </div>
              <div class="ml-20">
                <h1 class="hidden mt-86 uppercase rounded-xl">
                  {{ currentTxt1 }}
                </h1>
              </div>
            </div>
          </div>
          
          <!-- Aquí aplicamos clases de Tailwind CSS para el tamaño de la imagen -->
          <img
            class="flex bg-center bg-cover md:h-screen w-full pb-20"
            :src="currentImg"
            />
          <!-- Botones prev/next -->
          <a class="prev md:-mt-96" @click="prev" href="#">&#10094;</a>
          <a class="next md:-mt-96" @click="next" href="#">&#10095;</a>
        </div>
      </div>
    </header>
  </template>
  <script>
  // import { computed, onMounted} from '@vue/runtime-core';
  
  export default {
    // setup(){
  
      //   onMounted(()=>{
        //     starSlide()
    //   })
  
    //   let slider= ref([
    //     {img: "https://servidigital.co/wp-content/uploads/2015/11/fondo-escritorio.png"},
    //     {img: "https://servidigital.co/wp-content/uploads/2013/11/bg-table.jpg"},
    //     {img: "https://estardondeestes.com/movi/files/1454350908419/large-equipos1.jpg"},
    //   ])
    //   let text= ref([
    //     {msj:"Hola 1"},
    //     {msj:"Hola 2"} ,
    //     {msj:"Hola 3"},
  
    //   ])
    //   let timer= ref(0)
    //   let currentIndex = ref(0)
    
    //     function starSlide(){
      //         timer.value=setInterval(this.next, 100);
      //     }
      //    function next () {
        //      currentIndex.value += 1;
    //    }
    //     function prev () {
    //     currentIndex.value -= 1;
    //    }
  
    //      let currentImg=computed(()=>{
      //       return slider.value[Math.abs(this.currentIndex) % this.img.length];
      
      //     })
  
      //   return {slider,text,timer,starSlide,next,prev,currentImg}
      
    name: "Slider",
    data() {
      return {
        images: [
          //require("../assets/slide/6.jpg"),
          require("../assets/slide/9.jpg"),
          require("../assets/slide/10.jpg"),
          require("../assets/slide/11.jpg"),
          require("../assets/slide/12.jpg"),
          require("../assets/slide/13.jpg"),
        ],
        timer: null,
        currentIndex: 0,
        currentTexto: 0,
        currentText: 0,
        text: ["", "HOLA MUNDO1", "área de ", "HOLA MUNDO3"],
        text1: ["", "HOLA MUNDO1", "Hospitalización ", "HOLA MUNDO3"],
      };
    },
  
    mounted: function () {
      this.startSlide();
    },
  
    methods: {
      startSlide: function () {
        this.timer = setInterval(this.next, 150000);
      },
      
      next: function () {
        this.currentIndex += 1;
        this.currentTexto += 1;
        this.currentText += 1;
      },
      prev: function () {
        this.currentIndex -= 1;
        this.currentTexto -= 1;
        this.currentText -= 1;
      },
    },
  
    computed: {
      currentImg: function () {
        return this.images[Math.abs(this.currentIndex) % this.images.length];
      },
      currentTxt: function () {
        return this.text[Math.abs(this.currentTexto) % this.text.length];
      },
      currentTxt1: function () {
        return this.text1[Math.abs(this.currentText) % this.text1.length];
      },
    },
  };
  </script>
  
  <style>
  .prev,
  .next {
    cursor: pointer;
    position: absolute;
    width: auto;
    padding: 16px;
    color: white;
    font-weight: bold;
    font-size: 18px;
    transition: 0.7s ease;
    border-radius: 100px;
    text-decoration: none;
    user-select: none;
  }
  
  .next {
    right: 0;
  }
  
  .prev {
    left: 0;
  }
  
  .prev:hover,
  .next:hover {
    background-color: rgba(0, 0, 0, 0.9);
  }
  </style>
  